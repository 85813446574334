import React               from 'react';
import { Redirect, Route } from 'react-router-dom';
import { decode }          from 'jsonwebtoken';

import history from './../../utils/history';

import store               from '../../store';
// import { LOGIN_ACTIONS } from '../../constants/actions';

export function hasPermissionTo (permissions: string[], user: any) {
    return permissions.every(permission => user.permissions.includes(permission));
}

export function ProtectedRoute (path, component, user, permission) {
    if (!user || !isAuthenticated() || !hasPermissionTo(permission, user)) {
        return <Redirect path={path} to={'/login'}/>;
    }

    return <Route path={path} component={component}/>;
}

export function isAuthenticated () {
    return !!localStorage.getItem('token');
}

export function setUserInStore (user) {
    store.dispatch({
        type   : 'SET_USER',
        payload: user
    });
}

export function getUserFromToken () {
    const token = decode(localStorage.getItem('token'));

    return token ? token.user : null;
}

export function logout () {
    // store.dispatch({
    //     type: LOGIN_ACTIONS.LOGIN_ACTIONS_LOGOUT
    // });

    history.push('/login');
    window.location.reload();
}

export function checkUserToken() {
    const user = getUserFromToken();

    if (getUserFromToken()) {
        setUserInStore(user)
    }
}
