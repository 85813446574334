export const defaultValuesRegister = {
    isLoading: false,
    responseValidateByCode: null,
    isLoaded: false,
    user: {
        email: "",
        password: "",
        repassword:"",
        firstName: "",
        lastName: "",
        type_country: "",
        phone: ""
    },
    userRegisteredResponse: {}
}
