 
 import { 
 	REGISTER_ACTIONS
   } from "../../constants/actions";
  
   export const validateNewUser = (code) => {
       console.log(code)
     return {
       type: REGISTER_ACTIONS.REGISTER_ACTIONS_GET_EMAIL_BY_CODE,
       code
     }
   }

  export const receiveResponseValidateUser = (response) => {
    return {
      type: REGISTER_ACTIONS.REGISTER_ACTIONS_RECEIVE_RESPONSE_EMAIL_BY_CODE,
      response
    }
  }
  
  export const handleObjFiltered = (id, value) => {
    return {
      type: REGISTER_ACTIONS.REGISTER_ACTIONS_HANDLE_USER_INFO,
      id,
      value
    }
  }

  export const registerUser = (user, code, email) => {
    return {
      type: REGISTER_ACTIONS.REGISTER_ACTIONS_REGISTER_USER,
      user,
      code,
      email
    }
  }

  export const receiveResponseRegisterUser = (response) => {
    return {
      type: REGISTER_ACTIONS.REGISTER_ACTIONS_RECEIVE_RESPONSE,
      response
    }
  }

  export const resetRegister = () => {
    return {
      type: REGISTER_ACTIONS.REGISTER_ACTIONS_RESET,
    }
  }